import React from "react";
import styled from "styled-components";
import Grid from "styled-components-grid";
import { Formik } from "formik";
import * as Yup from "yup";

import { Button } from "../components/button";

const costCalculation = (seconds, isExclusive) => {
  const basePrice = isExclusive ? 150 : 100;
  return (seconds / 15) * basePrice;
};

const validationSchema = Yup.object().shape({
  length: Yup.number()
    .default(15)
    .min(15)
    .max(180)
    .required("Required"),
  is_looped: Yup.string()
    .oneOf(["yes", "no"])
    .default("no")
    .required("Required"),
  is_exclusive: Yup.string()
    .oneOf(["yes", "no"])
    .default("no")
    .required("Required"),

  usage: Yup.array(),
  usage_other: Yup.string(),
  genre: Yup.string(),
  genre_other: Yup.string(),
  mood: Yup.string(),
  mood_other: Yup.string(),

  tempo: Yup.string()
    .oneOf(["slow", "medium", "fast"])
    .default("medium")
    .required("Required"),
  instruments: Yup.string(),
  description: Yup.string(),
  format: Yup.string().default("mp3-320"),
  name: Yup.string().required("Required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Required"),
  notes: Yup.string()
});

const StyledField = styled.div`
  margin-bottom: 30px;
  width: 100%;

  &.error {
    input,
    textarea,
    .select select,
    .radio [type="radio"] + label:before,
    .checkbox [type="checkbox"] + label:before {
      border-color: #721c24;
    }

    input:focus,
    textarea:focus,
    .select select:focus,
    .radio [type="radio"]:focus + label::before,
    .checkbox [type="checkbox"]:focus + label::before {
      box-shadow: 0 0 0 1px #721c24;
    }

    .field-help {
      color: #721c24;
    }
  }

  input:focus,
  textarea:focus,
  .select select:focus,
  .radio [type="radio"]:focus + label::before,
  .checkbox [type="checkbox"]:focus + label::before {
    box-shadow: 0 0 0 1px #103c96;
  }

  input,
  textarea,
  .select select {
    border: none;
    box-shadow: none;
    background: #fff;
    height: 100%;
    width: 100%;
    outline: none;
    padding: 10px;
    border: solid 1px #103c96;
    border-radius: 4px;
    box-sizing: border-box;
  }

  .checkbox,
  .radio {
    position: relative;
    display: block;
    margin-bottom: 20px;

    [type="checkbox"],
    [type="radio"] {
      opacity: 0;
      position: absolute;
      left: -99999px;

      & + label {
        position: relative;
      }
      /* Empty square */
      & + label:before {
        content: "";
        margin-right: 10px;
        display: inline-block;
        box-sizing: border-box;
        vertical-align: text-top;
        width: 20px;
        height: 20px;
        background: #fff;
        border: 1px solid #103c96;
        border-radius: 2px;
      }
      /* Checked square */
      &:checked + label:after {
        content: "";
        position: absolute;
        left: 4px;
        top: 4px;
        background: #103c96;
        width: 12px;
        height: 12px;
        border-radius: 2px;
      }
    }

    [type="radio"] + label:before,
    [type="radio"]:checked + label:after {
      border-radius: 100%;
    }
  }

  .field-label {
    display: block;
    padding-bottom: 20px;
    font-weight: bold;

    &:first-letter {
      text-transform: uppercase;
    }
    &:after {
      content: ":";
    }
  }

  .select {
    position: relative;
    margin-bottom: 20px;

    &:before,
    &:after {
      content: "";
      position: absolute;
      pointer-events: none;
    }

    &:before {
      width: 30px;
      top: 1px;
      right: 1px;
      bottom: 1px;
      background: #fff;
      border-radius: 4px;
    }
    /* Select arrow */
    &:after {
      top: 0;
      width: 0;
      height: 0;
      right: 10px;
      bottom: 0;
      margin: auto;
      border-style: solid;
      border-width: 10px 5px 0;
      border-color: #103c96 transparent transparent;
    }

    select {
      cursor: pointer;
      padding-right: 45px;
      appearance: none;
    }
  }
`;

const Field = ({ label = null, name, error, touched, children, ...props }) => (
  <StyledField className={error && touched ? "error" : null}>
    <label htmlFor={name} className="field-label">
      {label || name}
    </label>
    {React.Children.count(children) ? children : <input id={name} {...props} />}
    {error && touched && <div className="field-help">{error}</div>}
  </StyledField>
);

const SelectField = ({
  label = null,
  name,
  error,
  touched,
  children,
  ...props
}) => (
  <Field {...{ label, name, error, touched }}>
    <div className="select">
      <select name={name} {...props}>
        {children}
      </select>
    </div>
  </Field>
);

const FieldSet = styled.fieldset`
  border-radius: 8px;
  border: none;
  box-shadow: 0 10px 20px 0 rgba(16, 60, 150, 0.1);
  background-color: #ffffff;
  margin-bottom: 60px;
  padding: 90px 120px;

  h2 {
    color: #103c96;
  }
`;

const EstimatedCost = styled.div`
  position: sticky;
  top: 10px;
  padding: 20px 40px;
  border: solid 1px #103c96;
  border-radius: 4px;
  box-shadow: 0 10px 20px 0 rgba(16, 60, 150, 0.1);
  color: #103c96;
  font-weight: 600;
  text-align: center;
  margin-left: 50%;

  span {
    font-weight: bold;
    font-size: 24px;
  }
`;

const Banner = styled.section`
  margin-top: 150px;
  h1 {
    color: #103c96;
  }

  p {
    margin-right: 30%;
    font-family: Montserrat;
    font-size: 23px;
    font-weight: 600;
    line-height: 1.67;
  }
`;

const Alert = styled.div`
  padding: 20px;
  margin: 20px 0;

  border: 1px solid black;
  border-radius: 4px;

  border-color: ${props => (props.error ? "#f5c6cb" : "#c3e6cb")};
  background-color: ${props => (props.error ? "#f8d7da" : "#d4edda")};
  color: ${props => (props.error ? "#721c24" : "#155724")};
`;

export default () => (
  <>
    <Banner>
      <h1>Order custom music</h1>
      <p>
        Fill out this simple form. Our team will contact you to discuss next
        steps. You are not obligated to fill all the fields, but by filling
        a much as you can, it will make our quoting procedure very easy.
      </p>
    </Banner>
    <Formik
      initialValues={{
        length: 15,
        is_exclusive: "no",
        is_looped: "no",
        genre: "",
        genre_other: "",
        mood: "",
        mood_other: "",
        usage: [],
        usage_other: "",
        tempo: "medium",
        instruments: "",
        description: "",
        format: "mp3-320",
        name: "",
        email: "",
        notes: ""
      }}
      initialStatus={{
        success: false,
        apiError: null
      }}
      onSubmit={async (values, actions) => {
        actions.setStatus({
          success: false,
          apiError: null
        });
        const data = {
          length: values.length,
          is_exclusive: values.is_exclusive === "yes",
          is_looped: values.is_looped === "yes",
          genre: values.genre || values.genre_other,
          mood: values.mood || values.mood_other,
          usage: [...values.usage, values.usage_other]
            .filter(v => v)
            .join(", "),
          tempo: values.tempo,
          instruments: values.instruments,
          description: values.description,
          format: values.format,
          name: values.name,
          email: values.email,
          notes: values.notes
        };
        let response;
        try {
          response = await fetch("/api/inquiry/", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer ]unKC@L_cZy9MAfvBd@>3y35"
            },
            body: JSON.stringify(data)
          });
        } catch {
          response = null;
        }
        if (response && response.status === 200) {
          actions.setStatus({
            success: true,
            apiError: null
          });
        } else if (response && response.status === 400) {
          const error = await response.json();
          actions.setStatus({
            success: false,
            apiError: error.msg
          });
          error.required.forEach(field =>
            actions.setFieldError(field, "Required")
          );
        } else {
          actions.setStatus({
            success: false,
            apiError:
              "Server error. Try again later or write to us contact@bluejay.studio."
          });
        }
      }}
      validationSchema={validationSchema}
    >
      {props => {
        const {
          values,
          touched,
          errors,
          status,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit
        } = props;
        return (
          <form onSubmit={handleSubmit}>
            <FieldSet>
              <Grid>
                <Grid.Unit size={1 / 2}>
                  <h2>Step 1: Music Request Information</h2>

                  {/* length */}
                  <SelectField
                    name="length"
                    type="number"
                    value={values.length}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.length}
                    touched={touched.length}
                    className={
                      errors.length && touched.length
                        ? "text-input error"
                        : "text-input"
                    }
                  >
                    <option value="15" label="15 seconds" />
                    <option value="30" label="30 seconds" />
                    <option value="45" label="45 seconds" />
                    <option value="60" label="1 minute" />
                    <option value="120" label="2 minutes" />
                    <option value="180" label="3 minutes" />
                    <option value="" label="other" />
                  </SelectField>
                  {/* is_exclusive */}
                  <Field
                    label="is exclusive"
                    error={errors.is_exclusive}
                    touched={touched.is_exclusive}
                    className={
                      errors.is_exclusive && touched.is_exclusive
                        ? "text-input error"
                        : "text-input"
                    }
                  >
                    <div className="radio">
                      <input
                        type="radio"
                        name="is_exclusive"
                        id="is_exclusive_yes"
                        value="yes"
                        checked={values.is_exclusive === "yes"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label htmlFor="is_exclusive_yes">Exclusive</label>
                    </div>
                    <div className="radio">
                      <input
                        type="radio"
                        name="is_exclusive"
                        id="is_exclusive_no"
                        value="no"
                        checked={values.is_exclusive === "no"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label htmlFor="is_exclusive_no">Non-exclusive</label>
                    </div>
                  </Field>
                  {/* is_looped */}
                  <Field
                    label="is looped"
                    error={errors.is_looped}
                    touched={touched.is_looped}
                    className={
                      errors.is_looped && touched.is_looped
                        ? "text-input error"
                        : "text-input"
                    }
                  >
                    <div className="radio">
                      <input
                        type="radio"
                        name="is_looped"
                        id="is_looped_yes"
                        value="yes"
                        checked={values.is_looped === "yes"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label htmlFor="is_looped_yes">Looped</label>
                    </div>
                    <div className="radio">
                      <input
                        type="radio"
                        name="is_looped"
                        id="is_looped_no"
                        value="no"
                        checked={values.is_looped === "no"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label htmlFor="is_looped_no">Non-looped</label>
                    </div>
                  </Field>
                  {/* genre */}
                  <Field
                    label="genre"
                    error={errors.genre}
                    touched={touched.genre}
                    className={
                      errors.genre && touched.genre
                        ? "text-input error"
                        : "text-input"
                    }
                  >
                    <div className="select">
                      <select
                        name="genre"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={{ display: "block" }}
                      >
                        <option value="">-------</option>
                        <option value="1920s">1920s</option>
                        <option value="1950s">1950s</option>
                        <option value="1960s">1960s</option>
                        <option value="1970s">1970s</option>
                        <option value="1980s">1980s</option>
                        <option value="action (various genres)">
                          action (various genres)
                        </option>
                        <option value="actionadventure">actionadventure</option>
                        <option value="african / tribal">
                          african / tribal
                        </option>
                        <option value="alternative">alternative</option>
                        <option value="ambient (electronic)">
                          ambient (electronic)
                        </option>
                        <option value="americana">americana</option>
                        <option value="arabic">arabic</option>
                        <option value="asian">asian</option>
                        <option value="baroque">baroque</option>
                        <option value="bigbeat / chemical">
                          bigbeat / chemical
                        </option>
                        <option value="bluegrass">bluegrass</option>
                        <option value="blues">blues</option>
                        <option value="caribbean / reggae">
                          caribbean / reggae
                        </option>
                        <option value="cartoon / humorous">
                          cartoon / humorous
                        </option>
                        <option value="celtic / irish / welsh / gaelic">
                          celtic / irish / welsh / gaelic
                        </option>
                        <option value="chanting / chant">
                          chanting / chant
                        </option>
                        <option value="Chanukah">Chanukah</option>
                        <option value="children / kid">children / kid</option>
                        <option value="chill / lounge">chill / lounge</option>
                        <option value="choral / choir">choral / choir</option>
                        <option value="christmas">christmas</option>
                        <option value="classical">classical</option>
                        <option value="commercials">commercials</option>
                        <option value="corporate / business">
                          corporate / business
                        </option>
                        <option value="country / western">
                          country / western
                        </option>
                        <option value="dance (all)">dance (all)</option>
                        <option value="dance / club ">dance / club </option>
                        <option value="disco">disco</option>
                        <option value="dubstep">dubstep</option>
                        <option value="easy listening">easy listening</option>
                        <option value="electronic">electronic</option>
                        <option value="ethnic / world">ethnic / world</option>
                        <option value="european">european</option>
                        <option value="film music">film music</option>
                        <option value="folk">folk</option>
                        <option value="gangster rap (gangsta)">
                          gangster rap (gangsta)
                        </option>
                        <option value="garage">garage</option>
                        <option value="gothic">gothic</option>
                        <option value="halloween / horror">
                          halloween / horror
                        </option>
                        <option value="healing">healing</option>
                        <option value="heavy metal">heavy metal</option>
                        <option value="hiphop ">hiphop </option>
                        <option value="hollywood blockbuster">
                          hollywood blockbuster
                        </option>
                        <option value="house">house</option>
                        <option value="industrial">industrial</option>
                        <option value="jazz">jazz</option>
                        <option value="kid / children">kid / children</option>
                        <option value="latin / spanish">latin / spanish</option>
                        <option value="lounge">lounge</option>
                        <option value="medieval">medieval</option>
                        <option value="meditation">meditation</option>
                        <option value="middle eastern/ indian">
                          middle eastern/ indian
                        </option>
                        <option value="motivational / inspirational">
                          motivational / inspirational
                        </option>
                        <option value="new age / newage">
                          new age / newage
                        </option>
                        <option value="nostalgic">nostalgic</option>
                        <option value="olympic">olympic</option>
                        <option value="popular / pop">popular / pop</option>
                        <option value="punk">punk</option>
                        <option value="ragtime">ragtime</option>
                        <option value="rap">rap</option>
                        <option value="rave">rave</option>
                        <option value="reggae / caribbean">
                          reggae / caribbean
                        </option>
                        <option value="retro / 1980s">retro / 1980s</option>
                        <option value="rhythm / blues">rhythm / blues</option>
                        <option value="rock">rock</option>
                        <option value="sacred">sacred</option>
                        <option value="spanish / latin">spanish / latin</option>
                        <option value="sports / sporty">sports / sporty</option>
                        <option value="surf">surf</option>
                        <option value="techno / trance">techno / trance</option>
                        <option value="technological">technological</option>
                        <option value="television leadin">
                          television leadin
                        </option>
                        <option value="trance">trance</option>
                        <option value="tribal">tribal</option>
                        <option value="underscores">underscores</option>
                        <option value="video game">video game</option>
                        <option value="western/ country">
                          western/ country
                        </option>
                        <option value="world (ethnic)">world (ethnic)</option>
                        <option value="yoga">yoga</option>
                        <option value="other">other</option>
                      </select>
                    </div>
                    {values.genre === "other" ? (
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="text"
                        name="genre_other"
                        autoFocus
                      ></input>
                    ) : null}
                  </Field>
                  {/* mood */}
                  <Field
                    label="mood"
                    error={errors.mood}
                    touched={touched.mood}
                    className={
                      errors.mood && touched.mood
                        ? "text-input error"
                        : "text-input"
                    }
                  >
                    <div className="select">
                      <select
                        name="mood"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={{ display: "block" }}
                      >
                        <option value="">-------</option>
                        <option value="aggressive">aggressive</option>
                        <option value="angry">angry</option>
                        <option value="apocalyptic">apocalyptic</option>
                        <option value="beautiful">beautiful</option>
                        <option value="big">big</option>
                        <option value="biographical">biographical</option>
                        <option value="bouncy">bouncy</option>
                        <option value="bright">bright</option>
                        <option value="calm">calm</option>
                        <option value="casual / every day">
                          casual / every day
                        </option>
                        <option value="chaotic">chaotic</option>
                        <option value="cheeky">cheeky</option>
                        <option value="cheerful">cheerful</option>
                        <option value="confident">confident</option>
                        <option value="contemplative">contemplative</option>
                        <option value="cool">cool</option>
                        <option value="dark">dark</option>
                        <option value="discovery">discovery</option>
                        <option value="disturbing">disturbing</option>
                        <option value="drama">drama</option>
                        <option value="dreamy">dreamy</option>
                        <option value="driving">driving</option>
                        <option value="edgy">edgy</option>
                        <option value="eerie">eerie</option>
                        <option value="elegant">elegant</option>
                        <option value="emotional">emotional</option>
                        <option value="energetic">energetic</option>
                        <option value="epic">epic</option>
                        <option value="erotic">erotic</option>
                        <option value="ethereal">ethereal</option>
                        <option value="evil">evil</option>
                        <option value="fashion">fashion</option>
                        <option value="fast">fast</option>
                        <option value="festive">festive</option>
                        <option value="frantic">frantic</option>
                        <option value="fun">fun</option>
                        <option value="funny">funny</option>
                        <option value="futuristic">futuristic</option>
                        <option value="groovy">groovy</option>
                        <option value="happy">happy</option>
                        <option value="hard">hard</option>
                        <option value="haunting">haunting</option>
                        <option value="heavy">heavy</option>
                        <option value="high tech">high tech</option>
                        <option value="hopeful">hopeful</option>
                        <option value="hypnotic">hypnotic</option>
                        <option value="inspirational / motivational">
                          inspirational / motivational
                        </option>
                        <option value="inspirational / motivational">
                          inspirational / motivational
                        </option>
                        <option value="intense">intense</option>
                        <option value="joyful">joyful</option>
                        <option value="light">light</option>
                        <option value="lonely">lonely</option>
                        <option value="magical">magical</option>
                        <option value="mechanical">mechanical</option>
                        <option value="meditative">meditative</option>
                        <option value="melancholy">melancholy</option>
                        <option value="mellow">mellow</option>
                        <option value="mournful">mournful</option>
                        <option value="mystical">mystical</option>
                        <option value="nature">nature</option>
                        <option value="ominous/ foreboding">
                          ominous/ foreboding
                        </option>
                        <option value="optimistic / positive">
                          optimistic / positive
                        </option>
                        <option value="passionate">passionate</option>
                        <option value="patriotic">patriotic</option>
                        <option value="peaceful">peaceful</option>
                        <option value="poignant">poignant</option>
                        <option value="powerful">powerful</option>
                        <option value="proud">proud</option>
                        <option value="quiet">quiet</option>
                        <option value="rebellious">rebellious</option>
                        <option value="reflective">reflective</option>
                        <option value="relaxed">relaxed</option>
                        <option value="repetitive">repetitive</option>
                        <option value="romantic">romantic</option>
                        <option value="sad / melancholy">
                          sad / melancholy
                        </option>
                        <option value="searching">searching</option>
                        <option value="sensual">sensual</option>
                        <option value="sentimental">sentimental</option>
                        <option value="serene">serene</option>
                        <option value="sexy/ sultry">sexy/ sultry</option>
                        <option value="sincere">sincere</option>
                        <option value="smooth">smooth</option>
                        <option value="soft">soft</option>
                        <option value="soothing">soothing</option>
                        <option value="southern">southern</option>
                        <option value="strange">strange</option>
                        <option value="suspense">suspense</option>
                        <option value="sweet">sweet</option>
                        <option value="tension">tension</option>
                        <option value="terror">terror</option>
                        <option value="tranquil">tranquil</option>
                        <option value="travel">travel</option>
                        <option value="uplifting">uplifting</option>
                        <option value="urban">urban</option>
                        <option value="wholesome">wholesome</option>
                        <option value="worried">worried</option>
                        <option value="other">other</option>
                      </select>
                    </div>
                    {values.mood === "other" ? (
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="text"
                        name="mood_other"
                        autoFocus
                      ></input>
                    ) : null}
                  </Field>
                  {/* usage */}
                  <Field
                    label="usage"
                    error={errors.usage}
                    touched={touched.usage}
                    className={
                      errors.usage && touched.usage
                        ? "text-input error"
                        : "text-input"
                    }
                  >
                    <div className="checkbox">
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="checkbox"
                        name="usage"
                        id="usage-1"
                        value="Web/Internet"
                      />
                      <label htmlFor="usage-1">Web/Internet</label>
                    </div>
                    <div className="checkbox">
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="checkbox"
                        name="usage"
                        id="usage-2"
                        value="Film"
                      />
                      <label htmlFor="usage-2">Film</label>
                    </div>
                    <div className="checkbox">
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="checkbox"
                        name="usage"
                        id="usage-3"
                        value="Other"
                      />
                      <label htmlFor="usage-3">Other</label>
                    </div>
                    {values.usage && values.usage.includes("Other") ? (
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="text"
                        name="usage_other"
                        autoFocus
                      ></input>
                    ) : null}
                  </Field>
                  {/* tempo */}
                  <Field
                    label="tempo"
                    error={errors.tempo}
                    touched={touched.tempo}
                    className={
                      errors.tempo && touched.tempo
                        ? "text-input error"
                        : "text-input"
                    }
                  >
                    <div className="radio">
                      <input
                        type="radio"
                        name="tempo"
                        id="tempo_slow"
                        value="slow"
                        checked={values.tempo === "slow"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label htmlFor="tempo_slow">Slow</label>
                    </div>
                    <div className="radio">
                      <input
                        type="radio"
                        name="tempo"
                        id="tempo_medium"
                        value="medium"
                        checked={values.tempo === "medium"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label htmlFor="tempo_medium">Medium</label>
                    </div>
                    <div className="radio">
                      <input
                        type="radio"
                        name="tempo"
                        id="tempo_fast"
                        value="fast"
                        checked={values.tempo === "fast"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label htmlFor="tempo_fast">Fast</label>
                    </div>
                  </Field>
                  {/* instruments */}
                  <Field
                    label="instruments"
                    error={errors.instruments}
                    touched={touched.instruments}
                    className={
                      errors.instruments && touched.instruments
                        ? "text-input error"
                        : "text-input"
                    }
                  >
                    <textarea
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="instruments"
                      id="instruments"
                      cols="30"
                      rows="10"
                    ></textarea>
                  </Field>
                  {/* description */}
                  <Field
                    label="description"
                    error={errors.description}
                    touched={touched.description}
                    className={
                      errors.description && touched.description
                        ? "text-input error"
                        : "text-input"
                    }
                  >
                    <textarea
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="description"
                      id="description"
                      cols="30"
                      rows="10"
                    ></textarea>
                  </Field>
                  {/* format */}
                  <Field
                    label="format"
                    error={errors.format}
                    touched={touched.format}
                    className={
                      errors.format && touched.format
                        ? "text-input error"
                        : "text-input"
                    }
                  >
                    <div className="radio">
                      <input
                        type="radio"
                        name="format"
                        value="mp3-320"
                        id="format_mp3_320"
                        checked={values.format === "mp3-320"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label htmlFor="format_mp3_320">
                        MP3 (320 KBPS - broadcast quality)
                      </label>
                    </div>
                    <div className="radio">
                      <input
                        type="radio"
                        name="format"
                        id="format_mp3_128"
                        value="mp3-128"
                        checked={values.format === "mp3-128"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label htmlFor="format_mp3_128">
                        MP3 (128 KBPS - web quality)
                      </label>
                    </div>
                    <div className="radio">
                      <input
                        type="radio"
                        name="format"
                        value="wave"
                        id="format_wave"
                        checked={values.format === "wave"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label htmlFor="format_wave">Wave</label>
                    </div>
                    <div className="radio">
                      <input
                        type="radio"
                        name="format"
                        id="format_aiff"
                        value="aiff"
                        checked={values.format === "aiff"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label htmlFor="format_aiff">AIFF</label>
                    </div>
                    <div className="radio">
                      <input
                        type="radio"
                        name="format"
                        value="midi"
                        id="format_midi"
                        checked={values.format === "midi"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label htmlFor="format_midi">Midi</label>
                    </div>
                  </Field>
                </Grid.Unit>
                <Grid.Unit size={1 / 2}>
                  {values.length ? (
                    <EstimatedCost>
                      Estimated Cost:
                      <br />
                      <span>
                        {costCalculation(
                          values.length,
                          values.is_exclusive === "yes"
                        )}
                        $
                      </span>
                    </EstimatedCost>
                  ) : null}
                </Grid.Unit>
              </Grid>
            </FieldSet>
            <FieldSet>
              <h2>Step 2: Contact Information</h2>
              {status && status.success ? (
                <Alert>
                  Thank you for filling out your information! We will contact
                  you shortly.
                </Alert>
              ) : null}
              {status && status.apiError ? (
                <Alert error>{status.apiError}</Alert>
              ) : null}
              {/* name */}
              <Field
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.name}
                touched={touched.name}
                className={
                  errors.name && touched.name
                    ? "text-input error"
                    : "text-input"
                }
              />
              {/* email */}
              <Field
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.email}
                touched={touched.email}
                className={
                  errors.email && touched.email
                    ? "text-input error"
                    : "text-input"
                }
              />
              {/* notes */}
              <Field
                label="Additional notes"
                error={errors.notes}
                touched={touched.notes}
                className={
                  errors.notes && touched.notes
                    ? "text-input error"
                    : "text-input"
                }
              >
                <textarea
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="notes"
                  id="notes"
                  cols="30"
                  rows="10"
                ></textarea>
              </Field>

              <Button primary type="submit" as="button" disabled={isSubmitting}>
                {isSubmitting ? "Sending" : "Send a request"}
              </Button>
            </FieldSet>
          </form>
        );
      }}
    </Formik>
  </>
);
